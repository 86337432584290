/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import {
    Form as BootstrapForm,
    Card, 
    Table, 
    Button,
    ButtonGroup,
    Alert,
    InputGroup,
    Stack,
    Container,
    Row,
    Col,
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Config from '../data/Config';


import "react-datepicker/dist/react-datepicker.css";

function removeControlCharacters(jsonString) {
    // Use a regular expression to replace control characters with an empty string
    return jsonString.replace(/[\u0000-\u001F]/g, '<br>');
}

export default function Form (props){
    let { formId } = useParams();
    const {authtoken} = props

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [dictionary, setDictionary] = useState(null);
    const navigate = useNavigate();
    
    const [downloadPdfReport, setDownloadPdfReport] = useState(false);
    const [showImagesInReport, setShowImagesInReport] = useState(false);
    const [showFacsimileInReport, setShowFacsimileInReport] = useState(true);
    
    const [urlReport, setUrlReport] = useState('');
    const [urlReportExpert, setUrlReportExpert] = useState('');
    
    useEffect(() => {
        setIsLoading(true)

        API.Get({ method:'getform', authtoken, formId})
        .then(res => {
            const {result, form} = res.data
            if ( result ){
                form.form_json =  form.form 
            
                try {
                    form.form = JSON.parse( form.form )
                } catch (e) {
                    form.form = JSON.parse( removeControlCharacters(form.form) )
                }

                setForm(form)
        
                API.Get({ method:'getdictionary', authtoken, version: form.form.formTemplateVersion})
                .then(res => {
                    const {result, dictionary } = res.data
                    // console.log(form.form.formTemplateVersion);
                    // console.log(dictionary);
                    if (result && dictionary){
                        console.log(dictionary)
                        setDictionary(dictionary)
                    }
                })

                
            }
        })
        .finally(()=>setIsLoading(false))

    },[])

    useEffect(() => {
        const pdf = downloadPdfReport ? '/pdf' : ''
        const params = {}
        if (!showFacsimileInReport){
            params.nofacsimile = 'y'
        }
        if (showImagesInReport){
            params.images = 'y'
            params.token = form.form.token
        }
        setUrlReport( buildUrl(`/report${pdf}/${form?.id}`, params) )
        setUrlReportExpert( buildUrl(`/report${pdf}/${form?.id}`, {...params, expert: 'y', token: form?.form.token} ) )

    },[form, downloadPdfReport, showFacsimileInReport, showImagesInReport])


    function buildUrl(path, params) {
        const url = new URL(path, Config.Domain);
        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, value);
        }
        return url.toString(); 
    }

    const getName = ( obj, showClause = false ) => {
		const key = obj.templateId ? obj.templateId : obj.id;
      	return (obj.name || obj.report || dictionary[key].report || dictionary[key].name)
				+ ( obj.comment ? ` (${obj.comment})` : '' )
				+ ( showClause ? `. ${dictionary[key].clause}` : '' )
    }

    const getFailChecks = ( form, showClause = false) => {
        let i = 1
        const failChecks = form?.apartment
        .map( (room) => (
        {
            ...room, 
            sections: room.nested.reduce( (sections, section ) => {
                        const checks = section.nested.reduce( (checks, check) => {
                            const str = `${getName(check, showClause)}`
                            return checks += (check?.value===false ? `<br/>&nbsp;&nbsp;&nbsp;&nbsp;${i++}. <span class="${ str.indexOf('Иное')>-1 ? 'bg-warning' : null}">${str}</span>` : '')
                        }, '' )
                        return sections += (checks!='' ? `${getName(section).toUpperCase()}:${checks}<br/>` : '')
                    }, '') +  (room.comment.length>0 ? `Другое:<br/>${i++} ${room.comment}<br/><hr/>` : '' )
        }
        ))
        .reduce( (sum, room) => ( sum += (room.sections!='' ? `<br/><h4 className="mt-5">${room.name.toUpperCase()}</h4>${room.sections}` : '') ), '' ) 
      
        return failChecks;
    }

   

    return (
        <>
            <Button variant="light" onClick={()=>navigate(-1)} className='mb-2'>&nbsp;&nbsp;&larr;&nbsp;&nbsp;</Button>
            <h1>{form?.contractNum || form?.address || 'Без адреса'}{form?.apartmentNum ? ", "+form?.apartmentNum : ""}</h1>
            {
                form && form.date_update &&
                <div className='small text-secondary'>Обновлена: {form.date_update?.replace('T', ' ')}</div>
            }
            {
                form && form.date_update_yandex_disk &&
                <div className='small text-secondary'>Cинхронизация в Яндекс Диск: {form.date_update_yandex_disk?.replace('T', ' ')}</div>
            }

            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item active"></li>
            </ol>
            
            {
                form &&
                <>
                    <Row>
                        <Col lg="9">
                            <Row>
                                <Col>
                                    {form.is_active==0 ? <div className='text-danger'>Приёмка отменена</div> : null}
                                    <div>Заказчик: {form.customer || 'не указан'}</div>
                                    <div>Специалист: {form.fio}</div>
                                    <div>Адрес: {form.address || 'не указан'}{form?.apartmentNum ? ", "+form?.apartmentNum : ""}</div>
                                    <div>Дата проведения: {form.timestamp?.replace('T', ' ')}</div>
                                </Col>
                                <Col lg="4">
                                    {
                                        Config.FeatureToggles.reportImagesToggle &&
                                        <>
                                            <BootstrapForm.Check 
                                                reverse
                                                type="checkbox"
                                                id="showImagesInReport"
                                                label="C фото"
                                                className='mb-3'
                                                checked={showImagesInReport}
                                                onChange={(e)=> setShowImagesInReport(e.target.checked) }
                                            />
                                            <BootstrapForm.Check 
                                                reverse
                                                type="checkbox"
                                                id="showFacsimileInReport"
                                                label="C факсимиле/печатью"
                                                className='mb-3'
                                                checked={showFacsimileInReport}
                                                onChange={(e)=> setShowFacsimileInReport(e.target.checked) }
                                            />
                                        </>
                                    }
                                    {
                                        !Config.FeatureToggles.pdfHide &&
                                        <BootstrapForm.Check 
                                            reverse
                                            type="checkbox"
                                            id="downloadPdfReport"
                                            label="скачать PDF"
                                            className='mb-3'
                                            checked={downloadPdfReport}
                                            onChange={(e)=> setDownloadPdfReport(e.target.checked) }
                                        />
                                    }
                                    <ButtonGroup className="mb-2 w-100" >
                                        <Button variant="primary" className="px-3" href={urlReport} target='_blank'>Акт осмотра</Button>
                                        <Button variant="primary" className="px-3" href={urlReportExpert} target='_blank'>✨Заключение</Button>
                                    </ButtonGroup>
                                    {
                                        Config.FeatureToggles.reportImagesToggle &&   
                                        <ButtonGroup size="md" className="mb-2">
                                            <Button variant="warning" className="px-3" href={`${Config.Domain}/report/kitchen/${formId}?images&token=${form.form.token}`} target='_blank'>Кухонная зона</Button>
                                        </ButtonGroup>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="rounded p-5 mt-4" style={{backgroundColor: '#FAF0DC'}}>
                                        {
                                            form.failChecksCountTotal > 0 ? (
                                                <div>Выявлено {form.failChecksCountTotal} недостатков из {form?.checksCountTotal} проверок:</div>
                                            ):(
                                                <div>В результате осмотра квартиры недостатки не выявлены</div>
                                            )
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: form && dictionary ? getFailChecks(form.form) : null }} />
                                    </div>
                                    <div className='mt-3'>{formId} / {form.form.token}</div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}