import logo from './logo.svg';
import './App.css';
import './styles.css';
import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Dashboard from './components/Dashboard';
import Forms from './components/Forms';
import Form from './components/Form';
import Users from './components/Users';
import User from './components/User';
import SettingsForm from './components/SettingsForm';
import SettingsReport from './components/SettingsReport';
import SettingsСonnections from './components/SettingsСonnections';
import Plans from './components/Plans';
import ReestrAdd from './components/ReestrAdd';
import { useCookies } from 'react-cookie'
import { Routes, Route } from "react-router-dom"
import * as API from './data/API';


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "./js/scripts.js"
import { useState } from 'react';



function App() {
	const [cookies, setCookie, removeCookie] = useCookies(['authtoken', 'username'])

	const [account, setAccount] = useState({id:null, name:null})
	const sidenavToggle = () => document.body.classList.toggle('sb-sidenav-toggled');

	function onLogin( authtoken, username ) {
		setCookie('authtoken', authtoken, { path: '/',  maxAge: 60*60*24*30})
		setCookie('username', username, { path: '/'})
		getAccount()
	}

	function RecoveryPasswordRequest( username = cookies?.username ) {
		API.Get({ method:'recoverypasswordrequest', username})
		.then(res => {
			alert( res.data.result ? 'Ссылка на форму восстановления пароля направлена на ваш e-mail. Не забудьте проверисть Спвм-папку. ' : 'Учетную запись с указанным адресом не удалось найти или он заблокирован.')
		})
		.catch( err => alert(err))
	}

	function onLogout( authtoken, username ) {
		removeCookie('authtoken')
	}

	const getAccount = () => {
		API.Get({ method:'getaccount', authtoken: cookies.authtoken})
		.then(({data}) => {
			console.log(data);
			const {id, name} = data.account
			setAccount({id, name})
		})
	}

	useState(()=>{
		getAccount()
	}, [])

	return (
		<>
		{
			!cookies.authtoken ? (

				<Login 
					username={cookies.username} 
					onLogin={onLogin} 
					RecoveryPasswordRequest={RecoveryPasswordRequest}
				/> 
			) : (
				<>
					<Header 
						sidenavToggle={sidenavToggle}
						onLogout={onLogout}
						RecoveryPasswordRequest={RecoveryPasswordRequest}
						username={cookies.username}
						account={account}
					/>
					<div id="layoutSidenav">
						<div id="layoutSidenav_nav">
							<Nav sidenavToggle={sidenavToggle}/>
						</div>
						<div id="layoutSidenav_content">
							<main>
								<div className="container-fluid p-4 align-left justify-content-start">
									<Routes>
										<Route path='/' 				element={<Forms authtoken={cookies.authtoken}/>} />
										<Route path='/dashboard' 		element={ cookies?.username=='stumarkin' ? <Dashboard authtoken={cookies.authtoken}/> : null} />
										<Route path='/forms' 			element={<Forms authtoken={cookies.authtoken}/>} />
										<Route path='/forms/:formId' 	element={<Form authtoken={cookies.authtoken}/>} />
										<Route path='/users' 			element={<Users authtoken={cookies.authtoken}/>} />
										<Route path='/users/:userId' 	element={<User authtoken={cookies.authtoken} username={cookies.username}/>} />
										<Route path='/settings-form' 	element={<SettingsForm authtoken={cookies.authtoken}/>} />
										<Route path='/settings-report' 	element={<SettingsReport authtoken={cookies.authtoken}/>} />
										<Route path='/settings-connections' 	element={<SettingsСonnections authtoken={cookies.authtoken}/>} />
										<Route path='/plans' 	    	element={<Plans authtoken={cookies.authtoken}  username={cookies.username}/>} />
										<Route path='/reestr' 	    	element={<ReestrAdd authtoken={cookies.authtoken}/>} />
									</Routes>
								</div>
							</main>
							<Footer/>
						</div>
					</div>
				</>
			)
		}
		</>
	);
}

export default App;
