/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    FloatingLabel,
    CloseButton, 
    Form,
    Button,
    Card,
    Image,
    Container,
    Row,
    Col,
    Tab,
    Tabs,
    Nav
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import * as Config from '../data/Config';


export default function SettingsСonnections ( props ){
    const {authtoken} = props

    const [isLoading, setIsLoading] = useState(false)

    // AmoCRM
    const [isAmoFeatureAvaliable, setIsAmoFeatureAvaliable] = useState(false)
    const [isAmoChanged, setIsAmoChanged] = useState(false)
    const [postAmoSuccess, setPostAmoSuccess] = useState(false)

    const [amoCredentials, setAmoCredentials] = useState({})
    const [amoCredentialsIsActive, setAmoCredentialsIsActive] = useState(0)
    const [amoCredentialsEndpoint, setAmoCredentialsEndpoint] = useState('')
    const [amoCredentialsToken, setAmoCredentialsToken] = useState('')
    
    const [amoCredentialsDateFieldId, setAmoCredentialsDateFieldId] = useState('')
    const [amoCredentialsGetaddressFieldId, setAmoCredentialsGetaddressFieldId] = useState('')
    
    const [amoCredentialsSetaddressFieldId, setAmoCredentialsSetaddressFieldId] = useState('')
    const [amoCredentialsReportFieldId, setAmoCredentialsReportFieldId] = useState('')
    const [amoCredentialsReportExpertFieldId, setAmoCredentialsReportExpertFieldId] = useState('')
    
    useEffect(() => {
        const isAmoFormChanged = (
            amoCredentialsIsActive != amoCredentials.is_active 
            || amoCredentialsEndpoint != amoCredentials.endpoint 
            || amoCredentialsToken != amoCredentials.token 
            || amoCredentialsDateFieldId != amoCredentials.date_field_id 
            || amoCredentialsGetaddressFieldId != amoCredentials.getaddress_field_id 
            || amoCredentialsSetaddressFieldId != amoCredentials.setaddress_field_id 
            || amoCredentialsReportFieldId != amoCredentials.report_field_id 
            || amoCredentialsReportExpertFieldId != amoCredentials.report_expert_field_id 
        )
        setIsAmoChanged( isAmoFormChanged )
        setPostAmoSuccess( postAmoSuccess && !isAmoFormChanged )
    }, [
        amoCredentialsIsActive, 
        amoCredentialsEndpoint, 
        amoCredentialsToken, 
        amoCredentialsDateFieldId, 
        amoCredentialsGetaddressFieldId, 
        amoCredentialsSetaddressFieldId, 
        amoCredentialsReportFieldId, 
        amoCredentialsReportExpertFieldId 
    ])

    useEffect(() => {
        GetAmoCredentials()
        GetYandexDiskCredentials()
        GetAccountLimits()
    }, [])

    const GetAccountLimits = () => {
        API.Get({ method:'getaccountplan', authtoken})
        .then(res => {
            console.log(res.data)
            const {result, plan} = res.data
            if (result && plan){
                setIsAmoFeatureAvaliable( !!+plan.feature_amocrm )
                setIsYandexDiskFeatureAvaliable( !!+plan.feature_yandex_disk )
            }
        })
    }

    const GetAmoCredentials = () => {
        setIsLoading(true)
        API.Get({ method:'getamocredentials', authtoken})
        .then(res => {
            const {result, amocredentials} = res.data
            if ( result && amocredentials ){
                const {is_active, endpoint, token, date_field_id, getaddress_field_id, setaddress_field_id, report_field_id, report_expert_field_id} = amocredentials
                setAmoCredentials( amocredentials )
                setAmoCredentialsIsActive( is_active )
                setAmoCredentialsEndpoint( endpoint )
                setAmoCredentialsToken( token )
                setAmoCredentialsDateFieldId( date_field_id )
                setAmoCredentialsGetaddressFieldId( getaddress_field_id )
                setAmoCredentialsSetaddressFieldId( setaddress_field_id )
                setAmoCredentialsReportFieldId( report_field_id )
                setAmoCredentialsReportExpertFieldId( report_expert_field_id )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const TestAmoConnection = () => {
        setIsLoading(true)
        API.Get({ method:'testamoconnection', authtoken, endpoint: amoCredentialsEndpoint, token: amoCredentialsToken })
        .then(res => {
            console.log(res.data);
            const {result, http_code, response} = res.data
            const header = (result ? 'Успешно' : 'Ошибка')
            alert( `${header}\n\nhttp_code = ${http_code} \n\nresponse = ${response}`)
        })
        .finally(()=>setIsLoading(false))
    }

    const PostAmoCredentials = () => {
        setIsLoading(true)
        API.Post({ method:'postamocredentials', authtoken}, {
            is_active: amoCredentialsIsActive,
            endpoint: amoCredentialsEndpoint,
            token: amoCredentialsToken,
            date_field_id: amoCredentialsDateFieldId,
            getaddress_field_id: amoCredentialsGetaddressFieldId,
            setaddress_field_id: amoCredentialsSetaddressFieldId,
            report_field_id: amoCredentialsReportFieldId,
            report_expert_field_id: amoCredentialsReportExpertFieldId
        })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostAmoSuccess( true )
                setIsAmoChanged( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }


    // YandexDisk
    const [isYandexDiskFeatureAvaliable, setIsYandexDiskFeatureAvaliable] = useState(false)
    const [isYandexDiskChanged, setIsYandexDiskChanged] = useState(false)
    const [postYandexDiskSuccess, setPostYandexDiskSuccess] = useState(false)

    const [yandexDiskCredentials, setYandexDiskCredentials] = useState({})
    const [yandexDiskCredentialsIsActive, setYandexDiskCredentialsIsActive] = useState(0)
    const [yandexDiskCredentialsEndpoint, setYandexDiskCredentialsEndpoint] = useState('https://cloud-api.yandex.net/v1/')
    const [yandexDiskCredentialsToken, setYandexDiskCredentialsToken] = useState('')
    
    useEffect(() => {
        const isYandexDiskFormChanged = (
            yandexDiskCredentialsIsActive != yandexDiskCredentials.is_active 
            || yandexDiskCredentialsEndpoint != yandexDiskCredentials.endpoint 
            || yandexDiskCredentialsToken != yandexDiskCredentials.token 
            
        )
        setIsYandexDiskChanged( isYandexDiskFormChanged )
        setPostYandexDiskSuccess( postYandexDiskSuccess && !isYandexDiskFormChanged )
    }, [
        yandexDiskCredentialsIsActive, 
        yandexDiskCredentialsEndpoint, 
        yandexDiskCredentialsToken
    ])

    const GetYandexDiskCredentials = () => {
        setIsLoading(true)
        API.Get({ method:'getyandexdiskcredentials', authtoken})
        .then(res => {
            const {result, yandexdiskcredentials} = res.data
            if ( result && yandexdiskcredentials ){
                const {is_active, endpoint, token} = yandexdiskcredentials
                setYandexDiskCredentials( yandexdiskcredentials )
                setYandexDiskCredentialsIsActive( is_active )
                setYandexDiskCredentialsEndpoint( endpoint )
                setYandexDiskCredentialsToken( token )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const TestYandexDiskConnection = () => {
        setIsLoading(true)
        API.Get({ method:'testyandexdiskconnection', authtoken, endpoint: yandexDiskCredentialsEndpoint, token: yandexDiskCredentialsToken })
        .then(res => {
            const {result, http_code, response} = res.data
            const header = (result ? 'Успешно' : 'Ошибка')
            alert( `${header}\n\nhttp_code = ${http_code} \n\nresponse = ${response}`)
        })
        .finally(()=>setIsLoading(false))
    }

    const PostYandexDiskCredentials = () => {
        setIsLoading(true)
        API.Post({ method:'postyandexdiskcredentials', authtoken}, {
            is_active: yandexDiskCredentialsIsActive,
            endpoint: yandexDiskCredentialsEndpoint,
            token: yandexDiskCredentialsToken,
        })
        .then(res => {
            console.log( res.data )
            if (res.data.result){
                setPostYandexDiskSuccess( true )
                setIsYandexDiskChanged( false )
            }
        })
        .finally(()=>setIsLoading(false))
    }
    
    return (
        <>
            <h1 >Интеграции с внешними системами</h1>

            <Alert variant="info" className='mb-3 d-block d-md-none'>
                C настройками удобнее работать на большом экране компьютера в панели управления по адресу <a href="#">my.priemka-pro.ru</a>
            </Alert>

            <Tab.Container id="left-tabs-example" defaultActiveKey="amo">
                <Row>
                    <Col sm={3} className='mb-5'>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="amo">AmoCRM</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="disk">Яндекс Диск</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="avito" disabled={true}>Авито <sup>Скоро</sup></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="amo">
                                <Container className='ms-0 ps-0'>
                                    <Row>
                                        <Col>
                                            {
                                                !isAmoFeatureAvaliable && 
                                                <Alert variant="warning" className='mb-3'>
                                                    Функция доступна для тарифов Бизнес и выше.
                                                </Alert>
                                            }
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Интеграция с AmoCRM"
                                                value={true} 
                                                checked={!!+amoCredentialsIsActive && isAmoFeatureAvaliable}
                                                isValid={!!+amoCredentialsIsActive && isAmoFeatureAvaliable}
                                                onChange={(e)=> setAmoCredentialsIsActive(+e.target.checked) }
                                                className='mb-3'
                                                disabled={!isAmoFeatureAvaliable}

                                            />
                                           
                                            <FloatingLabel label="URL подключения" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsEndpoint} 
                                                    onChange={(e)=> setAmoCredentialsEndpoint(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                    />
                                            </FloatingLabel>
                                            <FloatingLabel label="Долгосрочный токен" className='mb-4'>
                                                <Form.Control 
                                                    id="token" 
                                                    as="textarea"
                                                    placeholder=''
                                                    rows={3}
                                                    value={amoCredentialsToken} 
                                                    onChange={(e)=> setAmoCredentialsToken(e.target.value) }
                                                    style={{height: 150}}
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                />
                                            </FloatingLabel>
                                            <div className="row">
                                                <div className="col col-12 col-md-6">
                                                    <Button
                                                        variant="outline-primary"
                                                        className='w-50'
                                                        disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                        onClick={ TestAmoConnection }
                                                        size="sm"
                                                    >
                                                        Проверить подключение
                                                    </Button>
                                                </div>
                                            </div>

                                            <h5 className='mt-5 mb-3'>Забирать из AmoCRM поля:</h5>
                                            <FloatingLabel label="Имя заказчика" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={'Имя контакта в сделке (автоматически)'} 
                                                    onChange={(e)=> setAmoCredentialsEndpoint(e.target.value) }
                                                    disabled={true}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="Адрес приёмки или название ЖК (id поля в AmoCRM)" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsGetaddressFieldId} 
                                                    onChange={(e)=> setAmoCredentialsGetaddressFieldId(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel label="Дата приёмки (id поля в AmoCRM)" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsDateFieldId} 
                                                    onChange={(e)=> setAmoCredentialsDateFieldId(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                />
                                            </FloatingLabel>

                                            <h5 className='mt-5 mb-3'>Возвращать в AmoCRM в поля:</h5>
                                            <FloatingLabel label="Полный адрес (id поля в AmoCRM)" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsSetaddressFieldId} 
                                                    onChange={(e)=> setAmoCredentialsSetaddressFieldId(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                    />
                                            </FloatingLabel>
                                            <FloatingLabel label="Ссылка на Акт осмотра (id поля в AmoCRM)" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsReportFieldId} 
                                                    onChange={(e)=> setAmoCredentialsReportFieldId(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                    />
                                            </FloatingLabel>
                                            <FloatingLabel label="Ссылка на Заключение (id поля в AmoCRM)" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={amoCredentialsReportExpertFieldId} 
                                                    onChange={(e)=> setAmoCredentialsReportExpertFieldId(e.target.value) }
                                                    disabled={!+amoCredentialsIsActive || !isAmoFeatureAvaliable}
                                                    />
                                            </FloatingLabel>


                                            <div className="row py-3">
                                                <div className="col col-12 col-md-6">
                                                    <Button
                                                        variant={ postAmoSuccess ? 'success' : 'primary' }
                                                        className='w-100 mb-3 px-5 py-3'
                                                        disabled={!isAmoChanged || !isAmoFeatureAvaliable}
                                                        onClick={ PostAmoCredentials }
                                                    >
                                                        { postAmoSuccess ? 'Сохранено' : 'Сохранить изменения' }
                                                    </Button>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>

                            <Tab.Pane eventKey="disk">
                            <Container className='ms-0 ps-0'>
                                    <Row>
                                        <Col>
                                            {
                                                !isYandexDiskFeatureAvaliable && 
                                                <Alert variant="warning" className='mb-3'>
                                                    Функция доступна для тарифов Бизнес и выше.
                                                </Alert>
                                            }
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="Интеграция с Яндекс Диск"
                                                value={true} 
                                                checked={!!+yandexDiskCredentialsIsActive && isYandexDiskFeatureAvaliable}
                                                isValid={!!+yandexDiskCredentialsIsActive && isYandexDiskFeatureAvaliable}
                                                onChange={(e)=> setYandexDiskCredentialsIsActive(+e.target.checked) }
                                                className='mb-3'
                                                disabled={!isYandexDiskFeatureAvaliable}

                                            />
                                           
                                            <FloatingLabel label="URL подключения" className='mb-3'>
                                                <Form.Control 
                                                    id="endpoint"
                                                    type="text" 
                                                    placeholder=''
                                                    value={yandexDiskCredentialsEndpoint} 
                                                    onChange={(e)=> setYandexDiskCredentialsEndpoint(e.target.value) }
                                                    disabled={true}
                                                    />
                                            </FloatingLabel>
                                            <FloatingLabel label="OAuth-токен" className='mb-4'>
                                                <Form.Control 
                                                    id="token" 
                                                    type="text"
                                                    placeholder=''
                                                    value={yandexDiskCredentialsToken} 
                                                    onChange={(e)=> setYandexDiskCredentialsToken(e.target.value) }
                                                    disabled={!+yandexDiskCredentialsIsActive || !isYandexDiskFeatureAvaliable}
                                                />
                                            </FloatingLabel>
                                            <div className="row">
                                                <div className="col col-12 col-md-6">
                                                    <Button
                                                        variant="outline-primary"
                                                        className='w-50'
                                                        disabled={!+yandexDiskCredentialsIsActive || !isYandexDiskFeatureAvaliable}
                                                        onClick={ TestYandexDiskConnection }
                                                        size="sm"
                                                    >
                                                        Проверить подключение
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="row py-3">
                                                <div className="col col-12 col-md-6">
                                                    <Button
                                                        variant={ postYandexDiskSuccess ? 'success' : 'primary' }
                                                        className='w-100 mb-3 px-5 py-3'
                                                        disabled={!isYandexDiskChanged || !isYandexDiskFeatureAvaliable}
                                                        onClick={ PostYandexDiskCredentials }
                                                    >
                                                        { postYandexDiskSuccess ? 'Сохранено' : 'Сохранить изменения' }
                                                    </Button>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey="avito">
                            <Alert variant="info" className='mb-3'>
                                    Возможность скоро будет добавлена
                                </Alert>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}
